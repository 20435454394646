<template>
  <section class="hero-container">
    <div class="w-full md:w-1/2  h-full flex flex-col justify-center md:pr-16">
        <div class="flex  flex-col">
        <p class="text-2xl font-semibold">
        YOUR HEALTH, YOUR FUTURE,
      </p>
      <p class="text-2xl font-semibold mt-4">OUR COMMITMENT.</p>
      <p class="mt-6">
        Insucare helps over 3,000 Nigerians in choosing the best Health
        insurance plan that suits them, save for heath emergencies, set saving
        goals and locate nearest health service provider
      </p>
     </div>

      <div class="flex flex-col md:flex-row md:justify-between w-full mt-10">
        <button class="hero-btn"><i class="lab la-apple"></i> Get on iPhone</button>
        <button class="hero-btn"><i class="lab la-google-play"></i> Get on Android</button>
      </div>
    </div>
    <!-- <div class="flex w-1/2 h-full items-center justify-center bg-cover bg-center"
    style="background-image: url('/img/Ellipse-233.png')"
    >
      <img src="/img/hero-image.svg" alt="" />
    </div> -->
    <div class="flex w-full md:w-1/2 h-full items-center justify-center"
    >
      <img src="/img/hero-image.svg" alt="" class="bounce"/>
    </div>
  </section>
</template>

<script>
export default {
  components: {}
}
</script>

<style scoped>
.hero-container{
  @apply flex flex-col-reverse md:flex-row justify-center space-y-6 h-screen pl-10 pr-10 md:pl-24 md:pr-16;
}
.hero-btn{
    @apply w-full md:w-[47%] bg-primary rounded-lg text-[#FFFFFF] py-4 font-semibold hover:bg-transparent hover:text-primary border hover:border hover:border-primary;
}

.bounce {
  animation: bounce 6s ease infinite;

}
@keyframes bounce {
    70% { transform:translateY(0%); }
    80% { transform:translateY(-15%); }

}

</style>
