<template>
  <div class="text-center md:py-28 p-10 md:p-0">
    <p class="page-title">OUR PARTNERS</p>
    <section class="flex flex-row bg-transparent space-x-4 justify-center ">
      <div
        class="pt-6"
        v-for="partner in partners"
        :key="partner"
      >
            <img :src="`/img/${partner.img}`" class="h-24 w-24 rounded-full" />
      </div>
    </section>
  </div>
</template>
<script>
export default {
  components: {},
  data () {
    return {
      partners: [
        {
          img: 'NHIA-Logo.png'
        },
        {
          img: 'kgshia.png'
        },
        {
          img: 'zamchema.jpg'
        },
        {
          img: 'ohis.png'
        }
      ]
    }
  }
}
</script>
<style scoped>
.page-title {
  @apply font-bold text-2xl text-primary;
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}
.scrolling-wrapper::-webkit-scrollbar {
  display: none;
}
</style>
