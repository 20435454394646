<template>
<div class="">

  <!-- <p class="page-title my-16 text-center">DON'T JUST TAKE OUR WORD FOR IT</p> -->
  <section class="flex flex-col  md:flex-row bg-secondary py-14 md:space-x-4 space-y-6 md:space-y-0 md:px-2">
    <div class="w-[90%] md:w-1/3 h-[331px] bg-white flex flex-col p-12 rounded-lg mx-auto" v-for="review in reviews" :key="review">
      <div class="flex flex-col">
        <p class="h-36">
         {{ review.body }}
        </p>
        <div class="flex text-lg font-medium mt-10 space-x-4 items-center">
          <img :src="`/img/${review.img}`" class="h-12 w-12 rounded-full" />
          <span> {{ review.user }}</span>
        </div>
      </div>
    </div>
  </section>

</div>

</template>

<script>
export default {
  components: {},
  data () {
    return {
      reviews: [
        {
          body: 'I absolutely love this app! It has made my life so much healthier. The user interface is clean and easy to navigate, I can easily subscribe to any health insurance of my choice',
          user: 'Gloria Onu',
          img: 'user-review-3.jpg'
        },
        {
          body: 'The Insucare app is a game-changer! It is so convenient to have all my policies in one place and access them anytime, anywhere. The claims process is straightforward, and the customer service is top-notch. I feel more secure and in control of my coverage with this app.',
          user: 'Aderopo Oguniyi',
          img: 'user-review-1.jpg'
        },
        {
          body: 'Ihave been using this insurance app for a few months now, and I couldnot be happier. I easily for me and my dependents on the platform and can switch providers when needed.',
          user: 'Moses Iji',
          img: 'user-review-2.jpg'
        }

      ]
    }
  }
}
</script>

<style scoped>

.page-title{
    @apply font-bold text-2xl text-primary p-4 md:p-0;
}

</style>
