<template>
  <div id="app">
    <nav>
      <div class="flex  w-1/3 text-2xl font-semibold items-center">
        <img src="/logo.png" class="h-10 w-10">
        <span class="text-primary">Insu</span>
        <span class="text-secondary">care</span>
      </div>

      <div class=" w-1/3 flex space-x-3 items-center justify-center">
        <button class="btn-header"><i class="lab la-instagram text-[#FF5445]"></i></button>
        <button class="btn-header"><i class="lab la-facebook-f text-[#3C5A99]"></i> </button>
        <button class="btn-header"><i class="lab la-twitter text-[#00AAEC]"></i> </button>
      </div>

      <div class=" w-1/3 flex items-center justify-end">
        <button class="nav-btn">Download <i class="las la-arrow-down text-secondary"></i></button>

      </div>

    </nav>
    <!-- Mobile Header -->
    <div class="flex md:hidden bg-slate-100 p-10 items-center text-2xl font-semibold justify-between">
    <div class="flex items-center">
      <img src="/logo.png" class="h-10 w-10 mr-1">
        <span class="text-primary">Insu</span>
        <span class="text-secondary">care</span>
    </div>

        <div class=" w-1/3 flex space-x-3 items-center justify-center">
        <button class="btn-header-mobile"><i class="lab la-instagram text-[#FF5445]"></i></button>
        <button class="btn-header-mobile"><i class="lab la-facebook-f text-[#3C5A99]"></i> </button>
        <button class="btn-header-mobile"><i class="lab la-twitter text-[#00AAEC]"></i> </button>
      </div>

    </div>
     <!-- End of Mobile Header -->
    <router-view/>
    <FooterView/>

  </div>
</template>
<script>

import FooterView from '@/components/FooterView.vue'

export default {
  components: {
    FooterView
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn-header{
  @apply border border-primary p-1 px-2 rounded-md hover:text-white hover:bg-primary;
}
.btn-header-mobile{
  @apply border border-primary p-1 px-2 rounded-md;
}
.nav-btn{
  @apply bg-primary text-secondary rounded-md px-12 py-4 text-lg font-semibold hover:bg-transparent hover:text-primary border hover:border hover:border-primary;
}
</style>
