<template>
  <div class="bg-slate-100">
    <HeroView class=""/>
    <MissionView class="page-spacer"/>
    <FeaturesView class="page-spacer"/>
    <UserReviews/>
    <OurPartners/>
  </div>
</template>

<script>
import HeroView from '@/components/HeroView.vue'
import MissionView from '@/components/MissionView.vue'
import FeaturesView from '@/components/FeaturesView.vue'
import UserReviews from '@/components/UserReviews.vue'
import OurPartners from '@/components/OurPartners.vue'

export default {
  name: 'HomeView',
  components: {
    HeroView, MissionView, FeaturesView, UserReviews, OurPartners
  }
}
</script>
