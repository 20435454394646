<template>
 <div class="pt-20 md:pt-0">
    <section class="flex flex-col md:flex-row space-y-6 md:space-x-8 bg-[#FFB562] text-primary p-5 md:p-16 rounded-2xl">
      <div class="w-full md:w-[55%]">
        <p class="text-lg font-light mb-4">About Us</p>
        <p class="font-bold text-xl mb-4">OUR MISSION</p>
        <p>
          At Insucare, we invest in lasting and transparent relationships to
          build and sustain trust. Our systems hold us, partners and clients to
          account for our roles to build a sustainable health system. We commit
          to quality improvement that ensure clients enjoy the best experience
          in service delivery.
        </p>

        <button class="btn-contact">Contact Us</button>
      </div>

    <div class="w-full md:w-[45%] flex flex-col space-y-4">
        <img src="/img/about-us-1.svg" alt="">
        <img src="/img/about-us-2.svg" alt="">
    </div>

  </section>
 </div>
</template>
<script>
export default {
  components: {}
}
</script>

  <style scoped>
.btn-contact{
  @apply bg-primary py-3 px-12 rounded-lg text-white text-lg font-semibold mt-10 hover:bg-transparent hover:text-primary border border-primary hover:border hover:border-primary;
}
</style>
