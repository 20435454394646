<template>
  <section class="bg-transparent text-primary mt-24">
    <!-- First Row -->
    <div class="flex flex-col md:flex-row space-y-4 md:space-x-4">
      <div class="w-full md:w-1/2">
        <p class="text-lg font-light mb-10">Our Features</p>
        <p class="font-bold text-2xl">MANAGE AND TRACK</p>
        <p class="font-bold text-2xl mb-10">YOUR INSURANCE PLANS</p>

        <div class="bg-[#1C802E] feature-card text-white hover-feature-card">
          <div class="pr-10">
            <p class="font-bold text-lg">State Health Insurance Agency</p>

            <p class="mt-10">
              Register for a Mandatory State or National Health Insurance plan.
            </p>
          </div>

          <div class="flex justify-end mt-8">
            <img src="/img/nhia.svg" class="mt-8 justify-end" />
          </div>
        </div>
      </div>

      <div class="w-full md:w-1/2 flex flex-col">
        <div
          class="bg-white mt-10 feature-card text-primary hover-feature-card"
        >
          <div class="pr-10">
            <div class="pr-10">
              <p class="card-title">Private Health Insurance</p>

              <p class="mt-10">
                Get competitive prices for Private health insurance and register today for additional coverage.
              </p>
            </div>
          </div>
          <div class="flex justify-end mt-8">
            <img src="/img/feature-img-1.svg" alt="" class="mt-24" />
          </div>
        </div>
      </div>
    </div>
    <!-- Second Row -->
    <div
      class="flex flex-col md:flex-row justify-between mt-16 space-y-4 md:space-x-4"
    >
      <div class="w-full md:w-1/2 flex flex-col">
        <div
          class="bg-white mt-10 feature-card text-primary hover-feature-card"
        >
          <div class="pr-10">
            <p class="font-bold text-2xl">Save for Health</p>

            <p class="mt-10">
              Utilise our saving platform to save daily, weekly or monthly to ensure funds available to cover you and loved ones for health care services and insurance.
            </p>
          </div>

          <div class="flex justify-end mt-24">
            <img src="/img/feature-img-1.svg" />
          </div>
        </div>
      </div>

      <div class="w-full md:w-1/2 mt-10">
        <p class="font-bold text-2xl mt-20 mb-20 md:mt-7">
          INSUCARE HELPS YOU SAVE FOR HEALTH AND TRACK YOUR EXPENSES
        </p>

        <div class="bg-[#1C802E] feature-card text-white hover-feature-card">
          <div class="pr-10">
            <p class="font-bold text-2xl">Track your Health Expenses</p>

            <p class="mt-24">
              Track your health care expenses and use it to make decisions on your health or those of your loved ones.
            </p>
          </div>

          <div class="flex justify-end mt-16">
            <img src="/img/feature-img-2.svg" />
          </div>
        </div>
      </div>
    </div>

    <!-- Third Row -->
    <div
      class="flex flex-col md:flex-row space-y-4 md:space-x-4 bg-white rounded-2xl p-16 mt-24"
    >
      <div class="w-full flex md:w-1/2 mt-10 justify-center">
        <img src="/img/location.svg" alt="" class="" />
      </div>
      <div class="w-full md:w-1/2 mt-10">
        <p class="card-title">LOCATE YOUR NEAREST HEALTH CARE PROVIDER</p>
        <p class="mt-10">
          Search for the nearest health facilty, pharmacy, laboratory and other
          health services to make an appointment, access a health service or
          refer a loved one.
        </p>
      </div>
    </div>
    <!-- Get It On -->
    <div class="mt-24">
      <div
        class="w-full static flex flex-col md:flex-row bg-primary h-[459px] md:rounded-l-full"
      >
        <div
          class="md:relative md:left-0 md:w-[42%] hidden rounded-r-2xl bg-cover h-[459px] md:flex justify-end items-center"
          style="background-image: url('/img/user-1.png')"
        >
          <button
            class="bg-[#1C802E] w-[229px] h-[66px] rounded-lg text-white mr-4"
          >
            JUST DIAL *347*455#
          </button>
        </div>
        <div class="p-8 md:p-28 w-full md:w-[50%]">
          <p class="getit-title-text">
            NO APP? NO PROBLEM ACCESS YOUR HEALTH AND FINANCES WITH A SIMPLE
            DIAL
          </p>

          <div
            class="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mt-16"
          >
            <button class="getit-btn">
              <i class="lab la-apple"></i> Get on iPhone
            </button>
            <button class="getit-btn">
              <i class="lab la-google-play"></i> Get on Android
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  components: {}
}
</script>
<style scoped>
.feature-card {
  @apply w-full rounded-2xl pl-5 pt-5 md:pl-10 md:pt-10;
}
.hover-feature-card {
  /* hover:text-primary hover:bg-white */
  @apply hover:text-primary hover:bg-secondary;
}
.card-title {
  @apply font-bold text-2xl;
}
.getit-btn {
  @apply bg-secondary rounded-lg text-primary py-3 px-6 font-semibold hover:bg-transparent hover:text-secondary border border-secondary hover:border hover:border-secondary;
}
.getit-title-text {
  @apply text-white text-2xl font-bold my-2;
}
</style>
